import { Component, Input, OnInit, Optional, SecurityContext, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { CurafidaInputComponent } from '../curafida-input';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';

export interface CurafidaChoiceInputChoice {
    id: string;
    text: string;
    checked?: boolean;
}

@Component({
    selector: 'curafida-choice-input',
    templateUrl: './curafida-choice-input.component.html',
    styleUrls: ['./curafida-choice-input.component.scss'],
})
export class CurafidaChoiceInputComponent extends CurafidaInputComponent implements OnInit {
    @Input() choices: CurafidaChoiceInputChoice[];
    @Input() explanation: string;

    @Input() choiceType: 'multiple-choice' | 'single-choice' = 'multiple-choice';

    constructor(
        @Self()
        @Optional()
        public ngControl: NgControl,
        public translateService: TranslateService,
        public sanitizer: DomSanitizer,
    ) {
        super(ngControl, translateService, sanitizer);
    }

    ngOnInit() {
        if (this.choiceType === 'single-choice') {
            const selectedSingleChoiceId = this.getSelectedSingleChoiceId();
            if (selectedSingleChoiceId) this.updateSingleChoiceChecked(selectedSingleChoiceId);
        }
        this.formGroup.patchValue({ [this.formControlName]: this.choices });
    }

    onIonChangeMultipleChoice($event: { detail: { checked: boolean; value: string } }) {
        const choice = this.choices.find((c) => c.id === $event.detail.value);
        choice.checked = $event.detail.checked;
        this.formGroup.patchValue({ [this.formControlName]: this.choices });
    }

    onIonChangeSingleChoice($event: { detail: { checked: boolean; value: string } }) {
        this.updateSingleChoiceChecked($event.detail.value);
        this.formGroup.patchValue({ [this.formControlName]: this.choices });
    }

    sanitizeHtml(html: string) {
        return this.sanitizer.sanitize(SecurityContext.HTML, html);
    }

    getSelectedSingleChoiceId(): string {
        const selectedChoice = this.choices.find((c) => c.checked);
        return selectedChoice?.id;
    }

    private updateSingleChoiceChecked(id: string) {
        for (const singleChoice of this.choices) {
            singleChoice.checked = singleChoice.id === id;
        }
    }
}
