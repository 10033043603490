import { Component, Input, OnInit, Optional, SecurityContext, Self } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormGroup, NgControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ErrorMessage, errorMessagesConst } from '../../entities/error-const';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    template: '',
})
export abstract class CurafidaInputComponent implements ControlValueAccessor, OnInit {
    @Input() renderHtml = false;
    private _label: string;
    @Input() set label(labelText: string) {
        if (this.renderHtml) {
            this._label = this.sanitizer.sanitize(SecurityContext.HTML, labelText);
        } else {
            this._label = labelText;
        }
    }

    get label(): string {
        return this._label;
    }

    @Input() labelFirstMobile: string;
    @Input() labelSecondMobile: string;
    @Input() formControlName: string;

    @Input()
    formGroup: FormGroup;

    @Input()
    informationTooltip: string;

    value: any = '';

    @Input() isMobile = false;
    isRequired = false;

    errorMessages = errorMessagesConst;
    formErrors: ErrorMessage[] = [];

    protected constructor(
        // Retrieve the dependency only from the local injector,
        // not from parent or ancestors.
        @Self()
        // We want to be able to use the component without a form,
        // so we mark the dependency as optional.
        @Optional()
        public ngControl: NgControl,
        public translateService: TranslateService,
        public sanitizer: DomSanitizer,
    ) {
        if (this.ngControl != null) {
            // Setting the value accessor directly (instead of using
            // the providers) to avoid running into a circular import.
            this.ngControl.valueAccessor = this;
        }
    }

    registerOnChange(fn: any): void {
        this.onChange();
    }

    registerOnTouched(fn: any): void {
        this.onTouched();
    }

    writeValue(obj: any): void {
        this.value = obj;
    }

    ngOnInit() {
        this.updateLabel();
        if (this.formControlName && this.formGroup) {
            this.formErrors = [...this.errorMessages.filter((i) => i.formType === this.formControlName)];
        }
        if (this.formErrors.length < 1) {
            this.formErrors = [...this.errorMessages.filter((i) => i.formType === 'defaultTextInput')];
        }
        this.formGroup.controls[this.formControlName].statusChanges.subscribe((status) => {
            this.updateLabel();
        });
    }

    hasRequiredField(abstractControl: AbstractControl) {
        if (abstractControl?.validator) {
            const validator = abstractControl.validator({} as AbstractControl);
            if (validator && validator.required) {
                return true;
            }
        }
        return false;
    }

    updateLabel() {
        let originalLabel = this.label ?? '';
        if (this.label) {
            originalLabel = this.label.replace(' *', '');
            originalLabel = this.translateService.instant(originalLabel);
        }
        if (this.hasRequiredField(this.formGroup.controls[this.formControlName])) {
            this.isRequired = true;
            this.label = originalLabel + ' *';
        } else {
            this.isRequired = false;
            this.label = originalLabel;
        }
        // Unsure how mobile labels are handled, the below condition was just moved/copied from ngOnInit()
        if (!(this.labelFirstMobile && this.labelSecondMobile)) {
            this.labelFirstMobile = this.label;
            this.labelSecondMobile = this.label;
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private onChange() {}

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private onTouched() {}
}
